
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
.logo-container {
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
}
.App{
  font-family: Calibri;
  width: 100%;
  height: 99%;
  overflow: hidden;
}

.cScroll::-webkit-scrollbar{
  background-color: #f6f3fa;
  width: 5px;
  
}

.cScroll::-webkit-scrollbar-thumb{
  background: #0a8be7;
}