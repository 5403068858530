.profile_main{
  background-color: rgb(237, 235, 238);
  height: 100vh;
  overflow: auto;

  .profile_card{
    margin: 1rem;
  }
  .profile_button_div1{
    margin: 0.5rem;
    background-color: rgb(255, 255, 255);

    .btn{
      border-radius: 0.313rem;
      //color: rgba(1,1,1,0.6);
      font-size: 1.3rem;
      margin: 0.5rem;

      //&:hover{
      //  color: rgb(8, 101, 182);
      //  background-color: #F1F1F1;
      //}
      //&:active{
      //  background-color: #F1F1F1;
      //  color: rgb(8, 101, 182);
      //}
    }
    .btn1{
      margin-left: 1.875rem;
    }
  }
}