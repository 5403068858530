.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.icon {
  $size: 2.5rem;
  width: $size;
  height: $size;
  background-size: cover;
}
