@import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Nunito:300,400,700&amp;display=swap");

input[type="range"] {
    margin: auto;
    -webkit-appearance: none;
    color: rgb(8, 101, 182);
    position: relative;
    overflow: hidden;
    cursor: default;
}

::-webkit-slider-runnable-track {
    background: #ddd;
}

::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0; /* 1 */
    height: 40px;
    background: #fff;
    border-radius: 10px;
    box-shadow: -100vw 0 0 100vw rgb(8, 101, 182);
}


//Css by Thomas
.skills_main{
    .skills_card{
        //border: none;
        //box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05) !important;
    }
    .skills_head{
        color: rgb(8, 101, 182);
        font-weight: 600;
    }
    .profile_gr{
        color: #98989C;
    }
    .active_blue_btn{
        color: white;
        padding-right: 20px;
        padding-left: 20px;
        border-radius: 20px;
        margin-right: 20px;
        background-color: rgb(8, 101, 182);
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .not_active_white_btn{
        color: black;
        padding-right: 20px;
        padding-left: 20px;
        border-radius: 20px;
        margin-right: 20px;
        border: solid 1px #98989C;
        padding-top: 4px;
        padding-bottom: 4px;
        background-color: white;
    }
    .btn-ad-an-prd{
        border-radius: 20px;
        background-color: rgb(8, 101, 182);
        color: white;
        outline: none;
        border: none;
        margin: 0 !important;
    }
    .btn-ad-an-prd:focus{
        outline: none;
    }
}
@media only screen and (min-width: 992px) {
    .skills_main{
        $normalPadding: 1vw;
        $pagePadding: 2vw;
        $headFont: 1.5vw;
        $normalFont: 1vw;
        $cardPadding: 2vw;
        
        .skills_card{
            padding-left: $pagePadding + $cardPadding;
            padding-right: $pagePadding + $cardPadding;
            padding-top: $pagePadding;
            padding-bottom: $pagePadding;
            margin-top: $pagePadding;
        }
        .skills_head{
            font-size: $headFont;
        }
        .profile_gr{
            font-size: $normalFont;
        }
        .marginTop{
            margin-top: $normalPadding;
        }
        .btn-ad-an-prd{
            font-size: $normalFont;
            padding: 0.3vw 1.5vw;
        }
        .work_add_btn_img{
            height: 1vw;
            margin-left: 0.5vw;
        }
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .skills_main{
        $normalPadding: 1.3vw;
        $pagePadding: 2.3vw;
        $headFont: 1.8vw;
        $normalFont: 1.3vw;
        $cardPadding: 2.3vw;

        .skills_card{
            padding-left: $pagePadding + $cardPadding;
            padding-right: $pagePadding + $cardPadding;
            padding-top: $pagePadding;
            padding-bottom: $pagePadding;
            margin-top: $pagePadding;
        }
        .skills_head{
            font-size: $headFont;
        }
        .profile_gr{
            font-size: $normalFont;
        }
        .marginTop{
            margin-top: $normalPadding;
        }
        .btn-ad-an-prd{
            font-size: $normalFont;
            padding: 0.5vw 1.7vw;
        }
        .work_add_btn_img{
            height: 1.5vw;
            margin-left: 0.7vw;
        }
    }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
    .skills_main{
        $normalPadding: 1.6vw;
        $pagePadding: 2.6vw;
        $headFont: 2.1vw;
        $normalFont: 1.6vw;
        $cardPadding: 2.6vw;

        .skills_card{
            padding-left: $pagePadding + $cardPadding;
            padding-right: $pagePadding + $cardPadding;
            padding-top: $pagePadding;
            padding-bottom: $pagePadding;
            margin-top: $pagePadding;
        }
        .skills_head{
            font-size: $headFont;
        }
        .profile_gr{
            font-size: $normalFont;
        }
        .marginTop{
            margin-top: $normalPadding;
        }
        .btn-ad-an-prd{
            font-size: $normalFont;
            padding: 0.7vw 1.9vw;
        }
        .work_add_btn_img{
            height: 1.8vw;
            margin-left: 0.9vw;
        }
    }
}
@media screen and (max-width: 575px) {
    .skills_main{
        $normalPadding: 2.6vw;
        $pagePadding: 3.6vw;
        $headFont: 3.1vw;
        $normalFont: 2.6vw;
        $cardPadding: 3.6vw;

        .skills_card{
            padding-left: $pagePadding + $cardPadding;
            padding-right: $pagePadding + $cardPadding;
            padding-top: $pagePadding;
            padding-bottom: $pagePadding;
            margin-top: $pagePadding;
        }
        .skills_head{
            font-size: $headFont;
        }
        .profile_gr{
            font-size: $normalFont;
        }
        .marginTop{
            margin-top: $normalPadding;
        }
        .btn-ad-an-prd{
            font-size: $normalFont;
            padding: 1vw 2.1vw;
        }
        .work_add_btn_img{
            height: 2.8vw;
            margin-left: 1.3vw;
        }
    }
}
