@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .live_button {
        @apply m-2 pt-2 pb-2 pr-5 pl-5 hover:text-[#0865B6] hover:bg-[#F1F1F1] rounded-md font-semibold text-xl text-neutral-500
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.toolBar-btn{
  @apply flex items-center border-gray-700 border-l  px-2 hover:bg-gray-900 hover:text-white transition-all duration-300;
}

.editor-base{
 @apply prose prose-headings:m-0 prose-p:m-0  focus:outline-none max-w-none border border-gray-700 p-2 rounded; 
}