.header_main {
  background-color: white;
  .header_parent_div {
    padding-left: 1.25rem /* 20px */;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 50;

    @media (min-width: 640px) {
      padding-left: 1rem /* 56px */;
      padding-right: 1rem /* 56px */;
    }
    .header_nav_icon_div {
      // flex: 1 1 0%;

      .header_nav_icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .logo-container{
    margin-left: 0.5rem;
    color: rgb(8, 101, 182);
  }
  .header_searchbox_parent {
    display: none;
    --tw-bg-opacity: 1;
    background-color: rgb(232 232 232 / var(--tw-bg-opacity));
    padding-left: 0.75rem /* 12px */;
    padding-right: 0.75rem /* 12px */;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    border-radius: 0.375rem /* 6px */;

    @media (min-width: 768px) {
      display: flex;
    }
    .header_search_icon {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
    }
    .header_searchbox_input {
      font-size: 0.875rem /* 14px */;
      line-height: 1.25rem /* 20px */;
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
      --tw-bg-opacity: 1;
      background-color: rgb(232 232 232 / var(--tw-bg-opacity));
      border-style: none;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
  .header_main_icon_div {
    display: flex;
    align-items: center;

    .header_main_notification_icon_div {
      &:hover {
        cursor: pointer;
      }
      .notification_icon {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity));
      }
    }

    .header_main_avatar_div {
      display: flex;
      align-items: center;

      .avatar_icon {
        &:hover {
          cursor: pointer;
        }
      }

      .arrow_icon {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity));

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
